import { createContext } from 'react';

export interface UserData {
    id: number | null;
    name: string | null;
    email: string | null;
    viewed_tutorial: number | null;
    //refresh_token: string (What is this used for @harshDip)
}

interface UserContextData {
    currentUser: UserData | null;
    postLoginOp: (access_token: string) => void;
    postLogoutOp: () => void;
}

export const UserDataContext = createContext<UserContextData>({
    currentUser: null,
    postLoginOp: () => {
        console.log('');
    },
    postLogoutOp: () => {
        console.log('');
    },
});
