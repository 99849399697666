import React, { useContext } from 'react';
import { CheckCircle } from 'phosphor-react';

import { UserDataContext } from '../../contexts/UserDataContext';
import HeaderComponent from './components/HeaderComponent';
import FooterComponent from './components/FooterComponent';
import eztrackrTutorial from '../../assets/images/extension-tutorial/eztrackr-tutorial.gif';
import pinExtension from '../../assets/images/extension-tutorial/pin_extension.gif';

const ExtensionGettingStarted = () => {
    const { currentUser } = useContext(UserDataContext);

    return (
        <>
            <HeaderComponent />
            <div className={`flex h-screen w-full flex-col items-center px-12`}>
                <div className={`flex h-64 items-center text-3xl font-bold`}>
                    <h1>Welcome to Eztrackr!</h1>
                </div>
                <div className={`flex items-center`}>
                    <div className={`flex w-1/3 flex-col px-5`}>
                        <img src={pinExtension} alt="Pin extension" />
                        <div className={`flex flex-col items-center pt-12`}>
                            <h3 className={`py-2 text-2xl font-bold`}>
                                1. Pin extension
                            </h3>
                            <p>
                                For easy access to use the extension, don't
                                forget to pin it on toolbar!
                            </p>
                        </div>
                    </div>
                    <div className={`flex w-1/3 flex-col px-5`}>
                        <div className={`flex flex-col items-center`}>
                            {currentUser?.id ? (
                                <p
                                    className={`flex flex-col items-center text-xl`}>
                                    <span className={`my-2`}>
                                        <CheckCircle
                                            weight="fill"
                                            color="green"
                                            size={50}
                                        />
                                    </span>
                                    You are already signed in!
                                </p>
                            ) : (
                                <>
                                    <a href={`/chrome-extension`}>
                                        <button
                                            className={`rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700`}>
                                            Sign up
                                        </button>
                                    </a>
                                </>
                            )}
                            <div className={`flex flex-col items-center pt-12`}>
                                <h3 className={`py-2 text-2xl font-bold`}>
                                    2. Sign up
                                </h3>
                                <p>
                                    You need an account to save jobs and view
                                    your statistics.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={`flex w-1/3 flex-col px-5`}>
                        <img
                            src={eztrackrTutorial}
                            alt="Using Eztrackr on LinkedIn"
                        />
                        <div className={`flex flex-col items-center pt-12`}>
                            <h3 className={`py-2 text-2xl font-bold`}>
                                3. Save job easily
                            </h3>
                            <p>
                                Head to any job board like LinkedIn or Indeed,
                                or add them manually on any other website!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <FooterComponent />
        </>
    );
};

export default ExtensionGettingStarted;
