import React, { useState } from 'react';

function SearchBox({ handleFilterChange }: any) {
    const [query, setQuery] = useState('');

    const handleChange = (event: any) => {
        setQuery(event.target.value);
        handleFilterChange(event.target.value);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        handleFilterChange(query);
    };

    return (
        <form onSubmit={handleSubmit}>
            <input
                className="flex items-center rounded border px-4 py-1 text-sm placeholder-gray-400 focus:outline-none"
                type="text"
                value={query}
                onChange={handleChange}
                placeholder="Filter comany Name"
                name="query"
            />
        </form>
    );
}

export default SearchBox;
