import React, { useContext } from 'react';
import { DotsSixVertical, PlusCircle } from 'phosphor-react';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import JobCard from './JobCard';
import { BucketModel, JobModel } from '../../../../shared/models';
import BoardContext from '../../../../contexts/BoardContext';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

type bucketData = {
    bucket: any;
    jobs: JobModel[];
    buckets: Record<string, BucketModel>;
    color: string;
    index: number;
};

const Bucket: React.FC<bucketData> = ({
    bucket,
    jobs,
    buckets,
    color,
    index,
}) => {
    const context = useContext(BoardContext);
    if (!context) {
        return <></>;
    }
    const { boardId } = context;

    return (
        <Draggable draggableId={bucket.id} index={index}>
            {(provided) => (
                <div
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                    className={`mx-1 my-3 flex w-[300px] min-w-[300px] max-w-[300px] flex-col items-center rounded-lg border border-gray-100 bg-gray-100 p-3`}>
                    <div
                        className={`mb-5 flex w-full items-center justify-between rounded-md p-3`}
                        style={{ backgroundColor: color }}>
                        <h1
                            className={`text-md flex items-center font-bold text-white`}>
                            <span {...provided.dragHandleProps}>
                                <DotsSixVertical
                                    className={`cursor-move`}
                                    color="#FFF"
                                    size={24}
                                />
                            </span>
                            &nbsp;{bucket.name}
                        </h1>
                        <Link
                            to={`/boards/${boardId}/bucket/${bucket.id}/add-job`}
                            onClick={() => {
                                ReactGA.event({
                                    category: 'Add Job',
                                    action: 'User clicked add job button from bucket',
                                });
                            }}>
                            <div className={`cursor-pointer`}>
                                <PlusCircle
                                    size={24}
                                    weight="fill"
                                    fill="#FFF"
                                />
                            </div>
                        </Link>
                    </div>

                    {jobs.length > 0 && (
                        <Droppable
                            droppableId={bucket.id}
                            key={bucket.id}
                            type="task">
                            {(provided) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className={`flex h-full w-full flex-col items-center overflow-auto`}>
                                    {jobs.map((job: any, index: number) => (
                                        <JobCard
                                            key={job.id}
                                            job={job}
                                            index={index}
                                            buckets={buckets}
                                        />
                                    ))}
                                    <div className={`w-full py-2`}>
                                        <Link
                                            to={`/boards/${boardId}/bucket/${bucket.id}/add-job`}
                                            onClick={() => {
                                                ReactGA.event({
                                                    category:
                                                        'Add Job - bucket end',
                                                    action: 'User clicked add job button from bucket',
                                                });
                                            }}>
                                            <div
                                                className="group flex max-h-[120px] min-h-[120px] cursor-pointer
                                        flex-col items-center justify-center rounded-md border-2 border-dashed
                                        border-gray-200 p-3 hover:bg-gray-200">
                                                <p
                                                    className="hidden h-full w-full text-center font-bold text-white
                                            group-hover:block">
                                                    Add job
                                                </p>
                                            </div>
                                        </Link>
                                    </div>
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    )}
                    {jobs.length === 0 && (
                        <Droppable
                            droppableId={bucket.id}
                            key={bucket.id}
                            type="task">
                            {(provided) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className={`flex h-full w-full flex-col items-center`}>
                                    <Link
                                        to={`/boards/${boardId}/bucket/${bucket.id}/add-job`}
                                        className={`w-full`}
                                        onClick={() => {
                                            ReactGA.event({
                                                category:
                                                    'Add Job - bucket end',
                                                action: 'User clicked add job button from bucket',
                                            });
                                        }}>
                                        <div
                                            className="group flex max-h-[120px] min-h-[120px] cursor-pointer
                                        flex-col items-center justify-center rounded-md border-2 border-dashed
                                        border-gray-200 p-3 hover:bg-gray-200">
                                            <p
                                                className="hidden h-full w-full text-center font-bold text-white
                                            group-hover:block">
                                                Add job
                                            </p>
                                        </div>
                                    </Link>
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    )}
                </div>
            )}
        </Draggable>
    );
};

export default Bucket;
