import React from 'react';
import extension from '../../../assets/images/tutorial/Extension.png';
import addJob from '../../../assets/images/tutorial/add-job.png';
import trello from '../../../assets/images/tutorial/trello.png';
import feedback from '../../../assets/images/tutorial/feedback.png';
import stats from '../../../assets/images/tutorial/stats.png';

const content = [
    {
        title: 'Add Jobs with Ease',
        image: addJob,
        description:
            'Adding jobs is a breeze! Simply click on the plus button or the "Add Job" button located at the top right corner of your screen.',
    },
    {
        title: 'Effortless Job Tracking',
        image: extension,
        description:
            'Install our Chrome extension and add jobs from LinkedIn, and more jobs to come soon! Experience seamless integration and save valuable time.',
    },
    {
        title: 'Uncover Powerful Insights',
        image: stats,
        description:
            'Explore our comprehensive statistics feature to gain valuable insights into your job search progress.',
    },
    {
        title: 'Import from Trello',
        image: trello,
        description:
            "Eztrackr v3 users! A special welcome to you - you've been using Trello to manage your job search, and now you can effortlessly import all your data into Eztrackr.",
    },
    {
        title: 'Welcome!',
        image: feedback,
        description:
            "You've completed the Eztrackr tour. If you see anything wrong or want to suggest new features, use the form on bottom right. Get started and land that dream job!",
    },
];

type TutorialProps = {
    handleClose: () => void;
};

const Tutorial: React.FC<TutorialProps> = ({ handleClose }) => {
    const [currentStep, setCurrentStep] = React.useState(0);

    return (
        <div className={`flex h-full flex-col justify-between`}>
            <div
                className={`flex h-full flex-col items-center justify-between`}>
                <div className={`w-full`}>
                    <h1 className={`text-center text-xl font-bold`}>
                        {content[currentStep].title}
                    </h1>
                    <div className={`flex justify-center`}>
                        <img
                            src={content[currentStep].image}
                            alt={`tutorial-${currentStep}-image`}
                            className={`h-[355px] rounded-lg border-2 drop-shadow-lg`}
                        />
                    </div>
                </div>
                <p className={`pb-5 text-sm sm:text-base`}>
                    {content[currentStep].description}
                </p>
            </div>
            <div
                className={`flex w-full ${
                    currentStep === 0 ? 'justify-end' : 'justify-between'
                } border-t pt-2`}>
                {currentStep > 0 && (
                    <>
                        <button
                            className={`mx-2 inline-flex w-auto cursor-pointer select-none appearance-none items-center justify-center space-x-1 rounded-md border border-gray-200 bg-gray-200 px-3 py-1 text-sm font-medium text-gray-800`}
                            onClick={() => {
                                if (currentStep > 0) {
                                    setCurrentStep(currentStep - 1);
                                }
                            }}>
                            Previous
                        </button>
                    </>
                )}
                <button
                    className={`mx-2 inline-flex w-auto cursor-pointer select-none appearance-none items-center justify-center space-x-1 rounded-md border border-primary bg-primary px-3 py-1 text-sm font-medium text-white`}
                    onClick={() => {
                        if (currentStep < content.length - 1) {
                            setCurrentStep(currentStep + 1);
                        } else {
                            handleClose();
                        }
                    }}>
                    {currentStep === content.length - 1 ? 'Finish' : 'Next'}
                </button>
            </div>
        </div>
    );
};

export default Tutorial;
