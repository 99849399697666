import React, { useContext } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';

import { BucketModel, JobModel } from '../../../../shared/models';
import BoardContext from '../../../../contexts/BoardContext';

type job = {
    job: JobModel;
    index: number;
    buckets: Record<string, BucketModel>;
};

const JobCard: React.FC<job> = ({ job, index }) => {
    const context = useContext(BoardContext);
    if (!context) {
        return <></>;
    }
    const { boardId } = context;

    return (
        <>
            <Link to={`/boards/${boardId}/jobs/${job.id}`} className={`w-full`}>
                <div>
                    <Draggable draggableId={job.id} index={index}>
                        {(provided, snapshot) => (
                            <div
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                data-is-dragging={snapshot.isDragging}>
                                <div className={`py-2`}>
                                    <div
                                        className={`flex max-h-[120px] min-h-[120px] cursor-default flex-col justify-center rounded-md border border-l-4 border-l-[#7e14fe] bg-white p-3
                                ${snapshot.isDragging ? 'shadow-md' : ''}`}>
                                        <h2 className={`font-semibold`}>
                                            {job.name}
                                        </h2>
                                        <p>{job.position}</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Draggable>
                </div>
            </Link>
        </>
    );
};

export default JobCard;
