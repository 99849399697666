import axiosInstance from './AxiosInstance';
import { SelectModel } from '../models';

export async function getBucketsByBoard(boardId: string): Promise<any> {
    return await axiosInstance()
        .get<{ data: SelectModel[] }>(`buckets-of-board/${boardId}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.error('Error fetching buckets:', error);
            return [];
        });
}
