import React from 'react';

import logo from '../../assets/images/logo/eztracker-blue-icon.svg';

const Loading = () => {
    return (
        <div className={`bg-lime-500`}>
            <section className="bg-white">
                <div className="container mx-auto flex min-h-screen items-center px-6 py-12">
                    <div className="mx-auto flex max-w-sm flex-col items-center text-center">
                        <img src={logo} alt="Eztrackr logo" />
                        <h1 className="text-gray-800md:text-3xl mt-3 text-2xl font-semibold">
                            Eztrackr
                        </h1>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Loading;
