import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';

import './index.css';
import App from './App';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
        {/*<React.StrictMode>*/}
        <BrowserRouter>
            <App />
        </BrowserRouter>
        {/*</React.StrictMode>*/}
    </GoogleOAuthProvider>
);

reportWebVitals();
