import axiosInstance from './AxiosInstance';
import { JobModel } from '../models';

export async function getJob(jobId: string): Promise<any> {
    return await axiosInstance()
        .get<{ data: JobModel }>(`jobs/${jobId}`)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.error('Error fetching job: ', error);
            return [];
        });
}

export async function postJob(job: JobModel): Promise<any> {
    try {
        return await axiosInstance().put<any>(`jobs/${job.id}`, job);
    } catch (error) {
        console.error('Error posting job:', error);
        throw error;
    }
}

export async function deleteJobAPI(jobId: string): Promise<any> {
    try {
        return await axiosInstance().delete<any>(`jobs/${jobId}`);
    } catch (error) {
        console.error('Error deleting job:', error);
        throw error;
    }
}
