import { UserDataContext, UserData } from '../contexts/UserDataContext';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../shared/services/AxiosInstance';

interface Props {
    children: any;
    user: UserData | null;
}

const UserProviders: React.FC<Props> = ({ children, user }: Props) => {
    const [currentUser, setCurrentUser] = useState<UserData | null>(user);
    const navigate = useNavigate();

    const getUser = async (access_token: string) => {
        const config = {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        };

        return axiosInstance()
            .get<UserData>('/currentUser', config)
            .then((response) => {
                return {
                    id: response.data.id,
                    name: response.data.name,
                    email: response.data.email,
                    viewed_tutorial: response.data.viewed_tutorial,
                };
            })
            .catch((error) => {
                console.error(error);
                throw error;
            });
    };

    const postLoginOp = async (access_token: string) => {
        await localStorage.setItem('ez_access_token', access_token);
        const user = await getUser(access_token);
        localStorage.setItem(
            'viewedTour',
            user.viewed_tutorial ? 'true' : 'false'
        );
        setCurrentUser(user);
        navigate('/dashboard', { replace: true });
    };

    const postLogoutOp = async () => {
        await localStorage.clear();
        setCurrentUser(null);
        navigate('/');
    };

    return (
        <UserDataContext.Provider
            value={{ currentUser, postLoginOp, postLogoutOp }}>
            {children}
        </UserDataContext.Provider>
    );
};

export default UserProviders;
