import React from 'react';
import { RocketLaunch } from 'phosphor-react';

type ComingSoonProps = {
    handleClose: () => void;
};

const ComingSoon: React.FC<ComingSoonProps> = ({ handleClose }) => {
    return (
        <div className={`flex h-full flex-col`}>
            <h1 className={`flex items-center border-b py-2 text-xl font-bold`}>
                <span className={`mr-2`}>
                    <RocketLaunch />
                </span>
                Coming soon
            </h1>
            <div className={`py-3`}>
                <p className={`pb-5 text-sm sm:text-base`}>
                    We're working hard on bringing more exciting features to
                    you. Here's what you can expect in the near future:
                </p>
                <ul className={`list-inside list-disc text-sm`}>
                    <li>Create and re-order lists</li>
                    <li>More websites to parse - Indeed, Lever, and more</li>
                    <li>More powerful statistics</li>
                    <li>Advanced filtering</li>
                    <li>Create more boards</li>
                    <li>More data fields for jobs</li>
                </ul>
                <p className={`pt-5 text-sm font-semibold sm:text-base`}>
                    We would love your feedback! Please use the modal on the
                    bottom right to send us your thoughts.
                </p>
            </div>
            <div className={`flex justify-end`}>
                <button
                    className={`mx-2 inline-flex w-auto cursor-pointer select-none appearance-none items-center justify-center space-x-1 rounded-md border border-primary bg-primary px-3 py-1 text-sm font-medium text-white`}
                    onClick={() => {
                        handleClose();
                    }}>
                    Close
                </button>
            </div>
        </div>
    );
};

export default ComingSoon;
