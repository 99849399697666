import React from 'react';
import ReactGA from 'react-ga4';
import {
    ArrowCircleDownRight,
    ChartBarHorizontal,
    FadersHorizontal,
    Kanban as KanbanIcon,
    List,
    RocketLaunch,
} from 'phosphor-react';
import { Link } from 'react-router-dom';

type Props = {
    subpage: string | undefined;
    isMenuOpen: boolean;
    setIsMenuOpen: (value: boolean) => void;
    boardId: string;
    isComingSoonModalOpen: (value: boolean) => void;
};

const Menu: React.FC<Props> = ({
    subpage = 'board',
    isMenuOpen,
    setIsMenuOpen,
    boardId,
    isComingSoonModalOpen,
}) => {
    const handleComingSoon = () => {
        isComingSoonModalOpen(true);
        ReactGA.event({
            category: 'Coming Soon',
            action: 'User clicked on a feature that is coming soon',
        });
    };

    const MenuItems = () => {
        return (
            <>
                <Link to={`/boards/${boardId}/board`}>
                    <div
                        className={`my-1 flex cursor-pointer items-center rounded-md px-4 py-2 text-gray-700
                                 ${
                                     subpage === 'board'
                                         ? 'bg-primary text-white'
                                         : 'text-gray-700'
                                 }`}>
                        <KanbanIcon size={21} />
                        <span className="mx-4 text-sm font-semibold">
                            Board
                        </span>
                    </div>
                </Link>

                <Link to={`/boards/${boardId}/statistics`}>
                    <div
                        className={`my-1 flex cursor-pointer items-center rounded-md px-4 py-2 text-gray-700
                                 ${
                                     subpage === 'statistics'
                                         ? 'bg-primary text-white'
                                         : 'text-gray-700'
                                 }`}>
                        <ChartBarHorizontal size={21} />
                        <span className="mx-4 text-sm font-semibold">
                            Statistics
                        </span>
                    </div>
                </Link>

                <Link to={`/boards/${boardId}/settings`}>
                    <div
                        className={`my-1 flex cursor-pointer items-center rounded-md px-4 py-2 text-gray-700 
                                 ${
                                     subpage === 'settings'
                                         ? 'bg-primary text-white'
                                         : 'text-gray-700'
                                 }`}>
                        <FadersHorizontal size={21} />
                        <span className="mx-4 text-sm font-semibold">
                            Settings
                        </span>
                    </div>
                </Link>
                <Link to={`/import-from-trello`}>
                    <div
                        className={`my-1 flex cursor-pointer items-center rounded-md px-4 py-2 text-gray-700`}>
                        <ArrowCircleDownRight size={21} />
                        <span className="mx-4 text-sm font-semibold">
                            Import from Trello
                        </span>
                    </div>
                </Link>
            </>
        );
    };

    return (
        <>
            <div className="order-first hidden border-r px-5 md:flex md:min-w-[270px] md:max-w-[270px] md:flex-col">
                <div className={`flex h-full flex-col justify-between`}>
                    <div className="flex flex-col">
                        <MenuItems />
                    </div>
                    <div>
                        <div
                            onClick={handleComingSoon}
                            className={`mb-4 flex cursor-pointer items-center rounded-md bg-warning-light px-4 py-2`}>
                            <RocketLaunch size={21} color="#867B1B" />
                            <span className="mx-4 text-sm font-semibold text-warning-300">
                                Coming soon
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed bottom-5 left-5 md:hidden">
                <button
                    className={`rounded-full bg-primary p-3 text-white focus:outline-none`}
                    onClick={() => setIsMenuOpen(!isMenuOpen)}>
                    <List size={24} />
                </button>
                {isMenuOpen && (
                    <div className="absolute bottom-14 left-0 mt-2 w-52 origin-top-right rounded-md border border-gray-200 bg-white shadow-lg">
                        <MenuItems />
                        <div>
                            <div
                                onClick={handleComingSoon}
                                className={`mb-4 flex cursor-pointer items-center rounded-md bg-warning-light px-4 py-2`}>
                                <RocketLaunch size={21} color="#867B1B" />
                                <span className="mx-4 text-sm font-semibold text-warning-300">
                                    Coming soon
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default Menu;
