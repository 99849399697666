import React from 'react';
import { Navigate } from 'react-router-dom';
import { UserDataContext } from './contexts/UserDataContext';
import { useContext } from 'react';

type Props = {
    children: any;
};

const PrivateRoute = ({ children }: Props) => {
    const { currentUser } = useContext(UserDataContext);

    if (!currentUser?.id) {
        return <Navigate to="/" replace />;
    }

    return children;
};

export default PrivateRoute;
