import axios from 'axios';

const axiosInstance = () => {
    return axios.create({
        baseURL: `${
            process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000/api/'
        }`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('ez_access_token')}`,
        },
    });
};

export default axiosInstance;
