import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import HeaderComponent from '../../pages/onboarding/components/HeaderComponent';
import FooterComponent from '../../pages/onboarding/components/FooterComponent';

const Error404 = () => {
    const navigate = useNavigate();

    return (
        <div>
            <HeaderComponent />
            <section className="bg-white">
                <div className="container mx-auto flex min-h-screen items-center px-6 py-12">
                    <div>
                        <p className="text-sm font-medium text-blue-500">
                            404 error
                        </p>
                        <h1 className="mt-3 text-2xl font-semibold text-gray-800 md:text-3xl">
                            We can’t find that page
                        </h1>
                        <p className="mt-4 text-gray-500">
                            Sorry, the page you are looking for doesn't exist or
                            has been moved.
                        </p>

                        <div className="mt-6 flex items-center gap-x-3">
                            <button className="flex w-1/2 items-center justify-center gap-x-2 rounded-lg border bg-white px-5 py-2 text-sm text-gray-700 transition-colors duration-200 hover:bg-gray-100 sm:w-auto">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="h-5 w-5 rtl:rotate-180">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
                                    />
                                </svg>

                                <span>Go back</span>
                            </button>

                            <Link to="/">
                                <button
                                    onClick={() => navigate(-1)}
                                    className="w-1/2 shrink-0 rounded-lg bg-primary px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 sm:w-auto">
                                    Take me home
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <FooterComponent />
        </div>
    );
};

export default Error404;
