import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import logo from '../../../assets/images/logo/eztrackr-logo.svg';
import { UserDataContext } from '../../../contexts/UserDataContext';

const HeaderComponent = () => {
    const { currentUser } = useContext(UserDataContext);

    return (
        <div>
            <header className="body-font text-gray-600">
                <div className="container mx-auto flex flex-col flex-wrap items-center p-5 md:flex-row">
                    <Link
                        to="/"
                        className="title-font mb-4 flex items-center font-medium text-gray-900 md:mb-0">
                        <img src={logo} width={130} alt="Eztrackr logo" />
                    </Link>
                    <nav className="hidden flex-wrap items-center justify-center text-base md:ml-auto md:flex">
                        {currentUser?.id ? (
                            <a
                                href="/dashboard"
                                className="mr-5 hover:text-gray-900">
                                Dashboard
                            </a>
                        ) : (
                            <Link
                                to="/register"
                                className="mr-5 hover:text-gray-900">
                                Get started
                            </Link>
                        )}
                    </nav>
                </div>
            </header>
        </div>
    );
};

export default HeaderComponent;
