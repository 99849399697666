import React, { useContext, useEffect, useState } from 'react';
import { Field, Formik, Form } from 'formik';
import { CheckCircle } from 'phosphor-react';
import ReactGA from 'react-ga4';

import { BoardModel } from '../../../shared/models';
import { changeBoardName } from '../../../shared/services';
import BoardContext from '../../../contexts/BoardContext';

type board = {
    board: BoardModel;
};

const BoardSettings: React.FC<board> = ({ board }) => {
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        document.title = 'Board Settings';
    }, []);

    const initialValues = {
        name: board.name,
    };

    const context = useContext(BoardContext);
    if (!context) {
        return <></>;
    }
    const { updateBoard } = context;

    const validate = (values: any) => {
        const errors: any = {};
        if (!values.name) {
            errors.name = 'Required';
        }
        return errors;
    };

    const handleSubmit = async (values: any) => {
        try {
            await changeBoardName(board.id, values.name);
            await updateBoard();
            ReactGA.event({
                category: 'Board',
                action: 'Change Board Name',
            });
            setSuccess(true);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <div className={`px-5 py-12`}>
                <div className={`mb-5 px-5`}>
                    <h1 className={`mb-5 text-2xl font-bold`}>Settings</h1>
                    <p>
                        Rename your board here - more customization coming soon!
                    </p>

                    <div className={`mt-4`}>
                        <Formik
                            initialValues={initialValues}
                            validate={validate}
                            onSubmit={handleSubmit}>
                            {({ values, errors }) => (
                                <Form>
                                    <div className={`flex w-72 items-center`}>
                                        <Field
                                            name="name"
                                            type="text"
                                            value={values.name}
                                            autoComplete="off"
                                            placeholder="Board Name"
                                            className={`focus:ring-none block w-full rounded-md border border-gray-200 bg-white
                                               px-4 py-2 text-gray-700 shadow-sm
                                               focus:border-gray-200 focus:outline-none`}
                                        />
                                        <button
                                            className={`ml-4 flex w-24 items-center justify-center rounded-md border border-transparent
                                        ${
                                            success
                                                ? 'bg-green-100 text-green-900'
                                                : 'bg-blue-100 text-blue-900'
                                        } px-4 py-2 text-sm font-medium`}
                                            type="submit">
                                            {success ? (
                                                <CheckCircle
                                                    size={24}
                                                    weight="fill"
                                                    fill="#10B981"
                                                />
                                            ) : (
                                                'Save'
                                            )}
                                        </button>
                                    </div>
                                    {errors.name && (
                                        <div
                                            className={`mt-1 text-sm text-red-500`}>
                                            Name can't be empty
                                        </div>
                                    )}
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BoardSettings;
