import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { getFirstBoard } from '../../shared/services';
import { Loading } from '../../shared/messages';

const LoadBoard = () => {
    const navigate = useNavigate();

    useEffect(() => {
        getFirstBoard().then((data) => {
            navigate(`/boards/${data.id}/board`);
        });
    }, []);

    return (
        <div>
            <Loading />
        </div>
    );
};

export default LoadBoard;
