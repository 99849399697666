import React from 'react';

interface ChromeExtensionContextInterface {
    isDirectedFromChromeExtension: boolean;
}

const ChromeExtensionContext =
    React.createContext<ChromeExtensionContextInterface | null>(null);

export default ChromeExtensionContext;
