import React from 'react';

import HeaderComponent from './components/HeaderComponent';
import FooterComponent from './components/FooterComponent';

const ExtensionUninstalled = () => {
    return (
        <>
            <HeaderComponent />
            <div className={`my-12 flex w-full items-center justify-center`}>
                <iframe
                    src="https://docs.google.com/forms/d/e/1FAIpQLSf94fQ8IX0VseHq9-HX6iMpHWEJ7Z-BzUIJk_5QROHpj8cO_g/viewform?embedded=true"
                    width="640"
                    height="828"
                    frameBorder="0"
                    marginHeight={0}
                    marginWidth={0}>
                    Loading...
                </iframe>
            </div>
            <FooterComponent />
        </>
    );
};

export default ExtensionUninstalled;
