import React, { useContext } from 'react';
import { SignOut } from 'phosphor-react';
import ReactGA from 'react-ga4';

import logo from '../../assets/images/logo/eztrackr-logo.svg';
import { UserDataContext } from '../../contexts/UserDataContext';
import { Link } from 'react-router-dom';

const Menu = () => {
    const { postLogoutOp } = useContext(UserDataContext);

    const deleteAccessTokenInExtensionStorage = async () => {
        window.postMessage({ type: 'DELETE_ACCESS_TOKEN' });
    };

    const handleLogout = async () => {
        ReactGA.event({
            category: 'User',
            action: 'Logged out',
        });
        await deleteAccessTokenInExtensionStorage();
        postLogoutOp();
    };

    // menu navbar
    return (
        <div
            className={`flex h-14 w-full items-center justify-between border-b px-4`}>
            <Link to={'/dashboard'}>
                <div className="flex cursor-pointer justify-center px-2 py-4">
                    <img src={logo} width={100} alt="Eztrackr logo" />
                </div>
            </Link>
            <div className={`flex`} onClick={handleLogout}>
                <SignOut size={21} />
                <span className="mx-2 -mb-1 text-sm font-semibold">Logout</span>
            </div>
        </div>
    );
};

export default Menu;
