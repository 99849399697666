import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactModal from 'react-modal';
import ReactGA from 'react-ga4';

import Menu from './Menu';
import Kanban from './kanban/Kanban';
import BoardStatistics from './statistics/BoardStatistics';
import BoardSettings from './setting/BoardSettings';
import { getFirstBoard, setTourToViewed } from '../../shared/services';
import { Loading, Error500, Error404 } from '../../shared/messages';
import { BoardModel } from '../../shared/models';
import BoardContext from '../../contexts/BoardContext';
import ChromeExtensionContext from '../../contexts/ChromeExtensionContext';
import Navbar from './Navbar';
import Tutorial from './components/Tutorial';
import ComingSoon from './components/ComingSoon';
import { X } from 'phosphor-react';

ReactModal.setAppElement('#root'); // Set the appElement to the root element of your app

const Dashboard = () => {
    const { boardId, subpage } = useParams();

    const [subPage, setSubPage] = useState(subpage || 'board');
    const [board, setBoard] = useState<BoardModel>();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const isDirectedFromChromeExtension = useContext(
        ChromeExtensionContext
    )?.isDirectedFromChromeExtension;
    const [showToast, setShowToast] = useState(
        isDirectedFromChromeExtension
            ? localStorage.getItem('viewedTour') === 'true'
            : false
    );

    const [isComingSoonModalOpen, setIsComingSoonModalOpen] = useState(false);
    const [isTutorialModalOpen, setIsTutorialModalOpen] = useState<boolean>(
        localStorage.getItem('viewedTour') === 'false'
    );

    useEffect(() => {
        if (isDirectedFromChromeExtension) {
            window.location.reload();
        }
        isTutorialModalOpen ? setShowToast(false) : null;
        const fetchData = async () => {
            const data = await getFirstBoard();
            document.title = 'Board ' + data.name;
            setBoard(data);
        };
        fetchData().then(() => setIsLoading(false));
        setSubPage(subpage || 'board');
    }, [boardId, subpage]);

    const updateBoard = async () => {
        const data = await getFirstBoard();
        setBoard(data);
    };
    const handleCloseModal = async () => {
        setIsTutorialModalOpen(false);
        isDirectedFromChromeExtension ? setShowToast(true) : null;
        ReactGA.event({
            category: 'Tutorial',
            action: 'Closed tutorial',
        });
        const viewedTour = await localStorage.getItem('viewedTour');
        if (viewedTour === 'false') {
            await setTourToViewed();
            await localStorage.setItem('viewedTour', 'true');
        }
    };

    return (
        <>
            {isLoading && <Loading />}

            {!isLoading && (
                <>
                    <ReactModal
                        isOpen={isTutorialModalOpen}
                        overlayClassName={`fixed inset-0 bg-gray-500 bg-opacity-75 z-50`}
                        className={`absolute left-1/2 top-1/2 h-[60%] w-[90%] -translate-x-1/2 -translate-y-1/2 transform rounded-lg bg-white p-4 shadow-lg md:h-[70%] md:w-[600px]`}>
                        <Tutorial handleClose={handleCloseModal} />
                    </ReactModal>

                    <ReactModal
                        isOpen={isComingSoonModalOpen}
                        shouldCloseOnOverlayClick={true}
                        overlayClassName={`fixed inset-0 bg-gray-500 bg-opacity-75 z-50`}
                        className={`absolute left-1/2 top-1/2 h-[55%] w-[90%] -translate-x-1/2 -translate-y-1/2 transform rounded-lg bg-white p-4 shadow-lg md:h-[50%] md:w-[500px]`}>
                        <ComingSoon
                            handleClose={() => setIsComingSoonModalOpen(false)}
                        />
                    </ReactModal>

                    {!board ? (
                        <Error500 />
                    ) : (
                        <>
                            {!boardId ? (
                                <Error404 />
                            ) : (
                                <>
                                    {showToast ? (
                                        <div className="absolute left-1/2 top-3 -translate-x-1/2 transform rounded-lg bg-purple-800 px-4 py-2 text-center shadow-md">
                                            <div className="mx-auto flex max-w-sm items-center">
                                                <p className={`text-white`}>
                                                    Congratulations, Eztrackr's
                                                    Chrome extension is now
                                                    ready to use!
                                                </p>
                                                <p
                                                    onClick={() =>
                                                        setShowToast(false)
                                                    }
                                                    className={`ml-3 cursor-pointer`}>
                                                    <X color="#FFF" size={24} />
                                                </p>
                                            </div>
                                        </div>
                                    ) : (
                                        <></>
                                    )}

                                    <div className="flex min-h-screen flex-col">
                                        <Navbar />
                                        <div
                                            className="flex"
                                            style={{
                                                height: 'calc(100vh - 56px)',
                                            }}>
                                            <div
                                                className={`w-full overflow-y-auto overflow-x-hidden`}>
                                                {[
                                                    'board',
                                                    'statistics',
                                                    'settings',
                                                ].includes(subPage) ? (
                                                    <>
                                                        {subPage ===
                                                            'board' && (
                                                            <BoardContext.Provider
                                                                value={{
                                                                    updateBoard,
                                                                    boardId,
                                                                }}>
                                                                <Kanban
                                                                    board={
                                                                        board
                                                                    }
                                                                />
                                                            </BoardContext.Provider>
                                                        )}
                                                        {subPage ===
                                                            'statistics' && (
                                                            <BoardStatistics
                                                                board={board}
                                                            />
                                                        )}
                                                        {subPage ===
                                                            'settings' && (
                                                            <BoardContext.Provider
                                                                value={{
                                                                    updateBoard,
                                                                    boardId,
                                                                }}>
                                                                <BoardSettings
                                                                    board={
                                                                        board
                                                                    }
                                                                />
                                                            </BoardContext.Provider>
                                                        )}
                                                    </>
                                                ) : (
                                                    <Error404 />
                                                )}
                                            </div>
                                            <Menu
                                                subpage={subpage}
                                                isMenuOpen={isMenuOpen}
                                                setIsMenuOpen={setIsMenuOpen}
                                                boardId={boardId}
                                                isComingSoonModalOpen={
                                                    setIsComingSoonModalOpen
                                                }
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default Dashboard;
