import React from 'react';

import FooterComponent from './components/FooterComponent';
import HeaderComponent from './components/HeaderComponent';
import ForgotPasswordForm from './components/forms/ForgotPasswordForm';

const ForgotPassword = () => {
    return (
        <>
            <HeaderComponent />
            <div className="flex">
                <div className="h-screen w-1/2 border border-red-500 bg-gray-200">
                    Left section
                </div>
                <ForgotPasswordForm />
            </div>
            <FooterComponent />
        </>
    );
};

export default ForgotPassword;
