import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import HeaderComponent from './components/HeaderComponent';
import FooterComponent from './components/FooterComponent';
import { UserDataContext } from '../../contexts/UserDataContext';
import { GoogleChromeLogo, Kanban, ChartBar } from 'phosphor-react';
// import kanbanBoard from '../../assets/images/kanban-board.png';

const LandingPage = () => {
    const { currentUser } = useContext(UserDataContext);

    return (
        <div>
            <HeaderComponent />
            <section className="body-font px-12 text-gray-600">
                <div className="container mx-auto flex flex-col items-center py-24 md:flex-row">
                    <div className="mb-16 flex max-w-xl flex-col items-center text-center md:mb-0 md:items-start md:text-left lg:flex-grow">
                        <h1 className="title-font mb-4 text-6xl font-bold text-gray-900">
                            Get organized.
                        </h1>
                        <p className="mb-8 text-2xl leading-relaxed">
                            Take control of your job hunt with Eztrackr's
                            intuitive and easy-to-use platform, and say goodbye
                            to spreadsheets.
                        </p>
                        <div className="flex justify-center">
                            {currentUser?.id ? (
                                <Link to="/dashboard">
                                    <button
                                        className="flex transform items-center justify-center rounded-lg bg-primary px-6 py-2
                                    font-medium capitalize tracking-wide text-white transition-colors duration-300 focus:outline-none">
                                        <p className="font-bold text-white">
                                            View your board
                                        </p>
                                    </button>
                                </Link>
                            ) : (
                                <Link to="/register">
                                    <button
                                        className="flex transform items-center justify-center rounded-lg bg-primary px-6 py-2
                                    font-medium capitalize tracking-wide text-white transition-colors duration-300 focus:outline-none">
                                        <p className="font-bold text-white">
                                            Get started
                                        </p>
                                    </button>
                                </Link>
                            )}
                        </div>
                    </div>
                    {/*<div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                        <img className="object-cover object-center rounded" alt="hero"
                             src={logo}/>
                    </div>*/}
                </div>
            </section>
            <section className="body-font text-gray-600">
                <div className="flex justify-center px-5 py-24">
                    <div className="flex text-center">
                        <div className="mr-4 p-4">
                            <h2 className="title-font text-3xl font-medium text-gray-900 sm:text-4xl">
                                200+
                            </h2>
                            <p className="leading-relaxed">Users</p>
                        </div>
                        <div className="ml-4 p-4">
                            <h2 className="title-font text-3xl font-medium text-gray-900 sm:text-4xl">
                                1000+
                            </h2>
                            <p className="leading-relaxed">Jobs saved</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="body-font text-gray-600">
                <div className="container mx-auto px-5 py-24">
                    <div className="mb-20 text-center">
                        <h1 className="title-font mb-4 text-2xl font-medium text-gray-900 sm:text-3xl">
                            Powerful Features to Streamline Your Job Search
                        </h1>
                        <p className="text-gray-500s mx-auto text-base leading-relaxed lg:w-3/4 xl:w-2/4">
                            Optimize Your Job Hunt with Eztrackr's Robust
                            Functionality
                        </p>
                        <div className="mt-6 flex justify-center">
                            <div className="inline-flex h-1 w-16 rounded-full bg-primary"></div>
                        </div>
                    </div>
                    <div className="-mx-4 -mb-10 -mt-4 flex flex-wrap space-y-6 sm:-m-4 md:space-y-0">
                        <div className="flex flex-col items-center p-4 text-center md:w-1/3">
                            <div className="mb-5 inline-flex h-20 w-20 flex-shrink-0 items-center justify-center rounded-full bg-primary-100 text-primary">
                                <Kanban size={32} />
                            </div>
                            <div className="flex-grow">
                                <h2 className="title-font mb-3 text-lg font-medium text-gray-900">
                                    Powerful Kanban Board
                                </h2>
                                <p className="text-base leading-relaxed">
                                    Visualize your applications at every stage,
                                    easily move them across columns, and stay
                                    focused on landing your dream job.
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col items-center p-4 text-center md:w-1/3">
                            <div className="mb-5 inline-flex h-20 w-20 flex-shrink-0 items-center justify-center rounded-full bg-primary-100 text-primary">
                                <ChartBar size={32} />
                            </div>
                            <div className="flex-grow">
                                <h2 className="title-font mb-3 text-lg font-medium text-gray-900">
                                    Insightful Job Statistics
                                </h2>
                                <p className="text-base leading-relaxed">
                                    Gain valuable insights into your job search
                                    progress with our comprehensive job
                                    statistics.{' '}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col items-center p-4 text-center md:w-1/3">
                            <div className="mb-5 inline-flex h-20 w-20 flex-shrink-0 items-center justify-center rounded-full bg-primary-100 text-primary">
                                <GoogleChromeLogo size={32} />
                            </div>
                            <div className="flex-grow">
                                <h2 className="title-font mb-3 text-lg font-medium text-gray-900">
                                    Seamless Integration
                                </h2>
                                <p className="text-base leading-relaxed">
                                    Save jobs instantly from job boards across
                                    the web with our convenient Chrome extension
                                    directly to your board.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={`mt-16 flex w-full justify-center`}>
                        {currentUser?.id && (
                            <Link to="/register">
                                <button
                                    className="flex transform items-center justify-center rounded-lg bg-primary px-6 py-2
                                    font-medium capitalize tracking-wide text-white transition-colors duration-300 focus:outline-none">
                                    <p className="font-bold text-white">
                                        Get started
                                    </p>
                                </button>
                            </Link>
                        )}
                    </div>
                </div>
            </section>
            <FooterComponent />
        </div>
    );
};

export default LandingPage;
