import React from 'react';

const origins = [
    {
        origin: 'linkedin',
        label: 'LinkedIn',
        bgColor: 'bg-blue-100',
        textColor: 'text-blue-800',
    },
    {
        origin: 'eztrackr_platform',
        label: 'Eztrackr',
        bgColor: 'bg-green-100',
        textColor: 'text-green-800',
    },
    {
        origin: 'trello',
        label: 'Trello migration',
        bgColor: 'bg-cyan-100',
        textColor: 'text-cyan-800',
    },
    {
        origin: 'greenhouse',
        bgColor: 'bg-green-100',
        textColor: 'text-green-800',
    },
    {
        origin: 'glassdoor',
        bgColor: 'bg-green-100',
        textColor: 'text-green-800',
    },
];

const JobOriginChip = ({ origin }: { origin: string }) => {
    const selectedOrigin = origins.find((item) => item.origin === origin);

    if (selectedOrigin) {
        const { label, bgColor, textColor } = selectedOrigin;

        return (
            <div
                className={`rounded-md ${bgColor} px-2 py-1 text-sm font-bold ${textColor}`}>
                {label
                    ? label
                    : origin.charAt(0).toUpperCase() + origin.slice(1)}
            </div>
        );
    }

    // Handle unspecified origins
    return (
        <div className="rounded-md bg-gray-100 px-2 py-1 text-sm font-bold text-gray-800">
            {origin.charAt(0).toUpperCase() + origin.slice(1)}
        </div>
    );
};

export default JobOriginChip;
