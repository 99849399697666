import React, { useEffect, useState } from 'react';
import { Listbox } from '@headlessui/react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';

import { importBoardData } from '../../shared/services/Board';
import Navbar from '../board/Navbar';

const ImportFromTrello: React.FC = () => {
    const [authorized, setAuthorized] = useState<boolean>(false);
    const [boards, setBoards] = useState<any>([]);
    const [selectedBoard, setSelectedBoard] = useState<any>(null);
    const [step, setStep] = useState<number>(1);
    const [isImporting, setIsImporting] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [trellotoken, setTrellotoken] = useState<string>('');

    const navigate = useNavigate();

    const handleTrelloAuthorization = () => {
        return (window.location.href = `https://trello.com/1/authorize?expiration=never&scope=read,write,account&response_type=code&key=${process.env.REACT_APP_TRELLO_API_KEY}&redirect_uri=${process.env.REACT_APP_URL}/import-from-trello`);
    };

    const fetchBoards = async (token: string) => {
        setTrellotoken(token);
        try {
            // Fetch all workspaces/organizations for the user
            const organizationsResponse = await fetch(
                `https://api.trello.com/1/members/me/organizations?key=${process.env.REACT_APP_TRELLO_API_KEY}&token=${token}`
            );
            const organizations = await organizationsResponse.json();

            // Fetch all boards for each organization
            const boardsResponse = await Promise.all(
                organizations.map((organization: any) =>
                    fetch(
                        `https://api.trello.com/1/organizations/${organization.id}/boards?key=${process.env.REACT_APP_TRELLO_API_KEY}&token=${token}`
                    )
                )
            );
            const boards = await Promise.all(
                boardsResponse.map((response: any) => response.json())
            );
            // augment boards with organization name
            boards.forEach((organizationBoards: any, index: number) => {
                organizationBoards.forEach((board: any) => {
                    board.organizationName = organizations[index].displayName;
                });
            });

            const boardsInAllOrganizations = [].concat(...boards);
            setBoards(boardsInAllOrganizations);
            setSelectedBoard(boardsInAllOrganizations[0]);
        } catch (error) {
            setError(true);
            console.error('Error fetching boards and lists:', error);
        }
    };

    const importTrelloBoard = async () => {
        setIsImporting(true);
        try {
            // fetch list
            const listsResponse = await fetch(
                `https://api.trello.com/1/boards/${selectedBoard.id}/lists?key=${process.env.REACT_APP_TRELLO_API_KEY}&token=${trellotoken}`
            );
            // setLists([].concat(...listsInAllBoards));

            const lists = await listsResponse.json();
            const bucketsAndListToAdd = [];

            for (const list of lists) {
                const cardsResponse = await fetch(
                    `https://api.trello.com/1/lists/${list.id}/cards?key=${process.env.REACT_APP_TRELLO_API_KEY}&token=${trellotoken}`
                );
                const cards = await cardsResponse.json();
                bucketsAndListToAdd.push({
                    name: list.name,
                    jobs: cards.map((card: any) => ({
                        name: card.name,
                        description: card.desc,
                    })),
                });
            }
            // Send lists and jobs to backend - final step!
            try {
                await importBoardData({
                    buckets: bucketsAndListToAdd,
                });

                ReactGA.event({
                    category: 'Board',
                    action: 'Imported from Trello',
                });

                setTimeout(() => {
                    navigate('/dashboard');
                }, 3000);
            } catch (error) {
                setError(true);
                console.error('Error persisting imported board data:', error);
            }
            setSuccess(true);
        } catch (error) {
            setError(true);
            console.error('Error importing board:', error);
        }
    };

    useEffect(() => {
        const hash = window.location.hash;
        const token = hash.replace('#token=', '');
        window.history.replaceState(
            {},
            document.title,
            window.location.pathname
        );
        if (token) {
            console.log(token);
            setAuthorized(true);
            setStep(2);
            fetchBoards(token);
        }
    }, []);

    return (
        <>
            <Navbar />
            <div
                className="flex w-full flex-col items-center justify-center"
                style={{
                    height: 'calc(100vh - 56px)',
                }}>
                <div className={`flex flex-col items-center`}>
                    <h2 className={`text-3xl font-bold`}>Import from Trello</h2>
                    <p className={`text-sm`}>
                        In just 2 steps, you can import your desired board to
                        Eztrackr.
                    </p>
                </div>

                <div className="mt-12 flex w-[90%] flex-col items-center rounded-md border bg-gray-100 p-5 sm:h-80 sm:w-96">
                    <div className={`flex`}>
                        <h3 className={`text-lg`}>
                            <span className={`font-semibold`}>Step {step}</span>
                            :{step === 1 && ' Authorize Trello'}
                            {step === 2 && ' Select a board'}
                        </h3>
                    </div>

                    <div
                        className={`flex h-full w-full items-center justify-center`}>
                        {step === 1 && (
                            <div
                                className={`flex w-full flex-col items-center`}>
                                <button
                                    onClick={handleTrelloAuthorization}
                                    className="flex items-center rounded border-0 bg-primary
                                px-5 py-2 text-lg text-white focus:outline-none">
                                    <p className={`ml-1 text-white`}>
                                        Authorize Trello
                                    </p>
                                </button>
                                <p className={`mt-4 text-sm`}>
                                    We'll redirect you to Trello to authorize
                                    Eztrackr. This is required to import your
                                    board.
                                </p>
                            </div>
                        )}

                        {step === 2 &&
                            authorized &&
                            selectedBoard === null &&
                            !error && (
                                <div>
                                    <p>Loading your board...</p>
                                </div>
                            )}

                        {step === 2 &&
                            authorized &&
                            selectedBoard !== null &&
                            !error && (
                                <div
                                    className={`flex w-full flex-col items-center`}>
                                    <h2>Select a board</h2>
                                    <Listbox
                                        value={selectedBoard}
                                        onChange={setSelectedBoard}>
                                        {() => (
                                            <div className={`relative w-56`}>
                                                <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                                    <span className="text-md flex items-center justify-center">
                                                        {selectedBoard.name} in{' '}
                                                        {
                                                            selectedBoard.organizationName
                                                        }
                                                    </span>
                                                </Listbox.Button>
                                                <Listbox.Options
                                                    className={`text-md absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                                                    {boards.map(
                                                        (board: any) => (
                                                            <Listbox.Option
                                                                key={board.id}
                                                                className={`${
                                                                    board.id ===
                                                                    selectedBoard.id
                                                                        ? 'bg-primary text-white'
                                                                        : 'text-gray-900'
                                                                } relative cursor-default select-none border-b py-2 pl-10 pr-4`}
                                                                value={board}>
                                                                {() => (
                                                                    <div className="flex items-center">
                                                                        <span
                                                                            className={`${
                                                                                board.id ===
                                                                                selectedBoard.id
                                                                                    ? 'text-white'
                                                                                    : null
                                                                            } right-0 flex items-center pr-4`}>
                                                                            {
                                                                                board.name
                                                                            }{' '}
                                                                            in{' '}
                                                                            {
                                                                                board.organizationName
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </Listbox.Option>
                                                        )
                                                    )}
                                                </Listbox.Options>
                                            </div>
                                        )}
                                    </Listbox>
                                    <button
                                        onClick={importTrelloBoard}
                                        disabled={isImporting || success}
                                        className="mt-5 flex items-center rounded border-0
                                bg-primary px-5 py-2 text-base text-white focus:outline-none">
                                        {!success ? (
                                            <>
                                                {isImporting ? (
                                                    <>
                                                        <svg
                                                            className="h-4 w-4 animate-spin"
                                                            viewBox="3 3 18 18">
                                                            <path
                                                                className="fill-blue-800"
                                                                d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"></path>
                                                            <path
                                                                className="fill-blue-100"
                                                                d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"></path>
                                                        </svg>
                                                    </>
                                                ) : (
                                                    <p
                                                        className={`ml-1 text-white`}>
                                                        Import to Eztrackr
                                                    </p>
                                                )}
                                            </>
                                        ) : (
                                            <p className={`ml-1 text-white`}>
                                                Success! Redirecting to your
                                                dashboard...
                                            </p>
                                        )}
                                    </button>
                                </div>
                            )}

                        {((step != 1 && !authorized) || error) && (
                            <div
                                className={`flex flex-col items-center text-center`}>
                                <h2 className={`font-bold text-error`}>
                                    Error
                                </h2>
                                <p>
                                    We ran into an error. Please refresh the
                                    page and try again.
                                </p>
                                <p className={`text-sm`}>
                                    If the problem persists, please report
                                    through the feedback form at bottom right of
                                    the page.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ImportFromTrello;
