import React, { useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Link, useNavigate } from 'react-router-dom';
import { Field, Form, Formik, FormikProps, FormikValues } from 'formik';
import { Listbox } from '@headlessui/react';
import dayjs from 'dayjs';
import ReactGA from 'react-ga4';

import { deleteJobAPI, postJob } from '../../../../../shared/services';
import { JobModel, SelectModel } from '../../../../../shared/models';
import {
    ArrowSquareOut,
    CaretDown,
    CaretUp,
    HandsClapping,
    Warning,
    X,
} from 'phosphor-react';
import JobOriginChip from './JobOriginChip';

type props = {
    boardId: string;
    job?: JobModel;
    buckets: SelectModel[];
    bucketId?: string;
};

const JobForm: React.FC<props> = ({ boardId, job, buckets, bucketId }) => {
    const navigate = useNavigate();
    const formRef = useRef<null | FormikProps<FormikValues>>(null);
    const [selectedBucket, setSelectedBucket] = useState<SelectModel>(
        job
            ? buckets.find((k) => k.id === job.bucket_id) ?? buckets[0]
            : buckets.find((k) => k.id === bucketId) ?? buckets[0]
    );
    const [formErrors, setFormErrors] = useState<any>({});
    const [savingJob, setSavingJob] = useState<boolean>(false);
    const jobCreatedDate = dayjs(job?.created_at);
    const daysAgo = dayjs().diff(jobCreatedDate, 'day');

    let initialValues;
    if (!job) {
        initialValues = {
            id: uuidv4(),
            name: '',
            position: '',
            location: '',
            url: '',
            description: '',
            card_position: 0,
            created_at: new Date(),
        };
    } else {
        initialValues = {
            id: job.id,
            name: job.name,
            position: job.position,
            description: job.description || '',
            location: job.location || '',
            url: job.url || '',
            origin: job.origin || '',
            card_position: job.card_position || 0,
            created_at: new Date(job.created_at) || new Date(),
        };
    }

    const validate = (values: any) => {
        const errors: any = {};
        if (!values.name) {
            errors.name = 'Company name is required';
        }
        if (!values.position) {
            errors.position = 'Position name is required';
        }
        setFormErrors(errors);
        return errors;
    };

    const handleSubmit = async (values: any) => {
        setSavingJob(true);
        if (values['name'] === '' || values['position'] === '') {
            setSavingJob(false);
            return;
        }

        values['bucket_id'] = selectedBucket.id;

        if (job?.origin === '' || !job) {
            values['origin'] = 'eztrackr_platform';
        }

        return postJob(values)
            .then(() => {
                setSavingJob(false);
                ReactGA.event({
                    category: 'Job',
                    action: 'Create Job',
                    label: 'Job Created',
                });
                navigate(`/boards/${boardId}/board`);
            })
            .catch((error) => {
                setSavingJob(false);
                console.log(error);
            });
    };

    const handleDelete = async () => {
        if (!job) {
            return;
        }
        return deleteJobAPI(job.id)
            .then(() => {
                ReactGA.event({
                    category: 'Job',
                    action: 'Delete Job',
                    label: 'Job Deleted',
                });
                navigate(`/boards/${boardId}/board`);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const BucketSelect = () => {
        return (
            <Listbox value={selectedBucket} onChange={setSelectedBucket}>
                {() => (
                    <div className={`relative`}>
                        <Listbox.Button className="focus:ring-none relative block cursor-pointer text-sm font-bold focus:outline-none">
                            <div className="flex items-center justify-center text-sm">
                                <span className={`mr-2`}>
                                    {selectedBucket.name}
                                </span>
                                {job ? <CaretDown /> : <CaretUp />}
                            </div>
                        </Listbox.Button>
                        <Listbox.Options
                            className={`absolute ${
                                job ? 'top-8' : 'bottom-8'
                            } z-10 mt-1 max-h-56 overflow-auto rounded-md bg-white py-1 text-sm shadow-lg focus:outline-none`}>
                            {buckets.map((bucket) => (
                                <Listbox.Option
                                    key={bucket.id}
                                    className={`${
                                        bucket.id === selectedBucket.id
                                            ? 'bg-gray-100'
                                            : 'bg-white'
                                    } cursor-pointer select-none py-2 pl-3 pr-9`}
                                    value={bucket}>
                                    {() => (
                                        <div className="flex items-center">
                                            <span
                                                className={`right-0 flex items-center pr-4`}>
                                                {bucket.name}
                                            </span>
                                        </div>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </div>
                )}
            </Listbox>
        );
    };

    return (
        <>
            <div className={`flex flex-col justify-between md:h-full`}>
                <div className={`flex justify-between px-2 md:h-32 md:px-10`}>
                    <div>
                        {job ? (
                            <>
                                <h1 className={`text-3xl font-semibold`}>
                                    {job.position}
                                </h1>
                                <h3 className={`text-xl`}>{job.name}</h3>
                            </>
                        ) : (
                            <h1 className={`text-3xl font-semibold`}>
                                Create a new Job
                            </h1>
                        )}
                    </div>
                    <div>
                        <Link to={`/boards/${boardId}/board`}>
                            <X size={24} />
                        </Link>
                    </div>
                </div>
                <div
                    className={`flex flex-col overflow-hidden px-2 py-4 md:h-full md:flex-row md:px-10 md:py-0`}>
                    {job && (
                        <div
                            className={`flex h-full flex-col justify-start md:w-1/4`}>
                            <div className={`my-1 flex items-center`}>
                                <h3 className={`mr-3 text-sm text-gray-300`}>
                                    Status
                                </h3>
                                <BucketSelect />
                            </div>
                            <div className={`my-1 flex items-center`}>
                                <h3 className={`mr-3 text-sm text-gray-300`}>
                                    Added
                                </h3>
                                <p className={`text-sm font-semibold`}>
                                    {jobCreatedDate.format('MMMM D, YYYY')}
                                </p>
                            </div>
                            <div className={`my-1 flex items-center`}>
                                <h3 className={`mr-3 text-sm text-gray-300`}>
                                    Created from
                                </h3>
                                <div>
                                    <JobOriginChip origin={job.origin} />
                                </div>
                            </div>
                            {(selectedBucket.name === 'Wishlist' ||
                                selectedBucket.name === 'Applied') && (
                                <div className="my-1">
                                    <div className="flex items-center">
                                        <p
                                            className={`flex items-center text-sm font-semibold
                                        ${
                                            daysAgo <= 10
                                                ? 'bg-yellow-100 text-yellow-700'
                                                : 'bg-red-100 text-red-700'
                                        }
                                        rounded-md px-2 py-1`}>
                                            <span className="px-1">
                                                {daysAgo <= 10 ? (
                                                    <HandsClapping />
                                                ) : (
                                                    <Warning />
                                                )}
                                            </span>
                                            {selectedBucket.name === 'Wishlist'
                                                ? 'Added'
                                                : 'Applied'}{' '}
                                            {daysAgo} day
                                            {daysAgo !== 1 ? 's' : ''} ago
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    {job && <div className="border-l"></div>}
                    <div
                        className={`${
                            job ? 'md:w-3/4' : 'md:w-full'
                        } overflow-auto px-2 md:px-10`}>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={handleSubmit}
                            validateOnBlur={false}
                            validateOnChange={false}
                            validate={validate}
                            innerRef={formRef}>
                            {({ values, handleChange }) => (
                                <Form>
                                    <div className="mt-4 grid grid-cols-1 gap-6 md:grid-cols-2">
                                        <div className="col-span-2 mb-4 md:col-span-1">
                                            <label
                                                htmlFor="name"
                                                className={`block text-sm font-bold text-gray-700 ${
                                                    formErrors.name
                                                        ? 'text-red-500'
                                                        : 'text-gray-700'
                                                }`}>
                                                <span>Company Name*</span>
                                            </label>
                                            <Field
                                                type="text"
                                                id="name"
                                                name="name"
                                                autoComplete="off"
                                                onChange={handleChange}
                                                value={values.name}
                                                className={`focus:ring-none mt-2 block w-full rounded-md border border-gray-200 bg-white px-4 py-2 text-sm text-gray-700 shadow-sm focus:border-gray-200 focus:outline-none`}
                                            />
                                        </div>
                                        <div className="col-span-2 mb-4 md:col-span-1">
                                            <label
                                                htmlFor="position"
                                                className={`block text-sm font-bold text-gray-700 ${
                                                    formErrors.position
                                                        ? 'text-red-500'
                                                        : 'text-gray-700'
                                                }`}>
                                                <span>Position*</span>
                                            </label>
                                            <Field
                                                type="text"
                                                id="position"
                                                name="position"
                                                autoComplete="off"
                                                onChange={handleChange}
                                                value={values.position}
                                                className={`focus:ring-none mt-2 block w-full rounded-md border border-gray-200 bg-white px-4 py-2 text-sm text-gray-700 shadow-sm focus:border-gray-200 focus:outline-none`}
                                            />
                                        </div>
                                        <div className="col-span-2 mb-4 md:col-span-1">
                                            <label
                                                htmlFor="url"
                                                className={`text-gray-700} block text-sm font-bold`}>
                                                <p
                                                    className={`flex items-center`}>
                                                    <span className={`mr-2`}>
                                                        URL
                                                    </span>
                                                    <a href={`${values.url}`}>
                                                        <ArrowSquareOut />
                                                    </a>
                                                </p>
                                            </label>
                                            <Field
                                                type="text"
                                                id="url"
                                                name="url"
                                                autoComplete="off"
                                                onChange={handleChange}
                                                value={values.url}
                                                className={`focus:ring-none mt-2 block w-full rounded-md border border-gray-200 bg-white px-4 py-2 text-sm text-gray-700 shadow-sm focus:border-gray-200 focus:outline-none`}
                                            />
                                        </div>
                                        <div className="col-span-2 mb-4 md:col-span-1">
                                            <label
                                                htmlFor="location"
                                                className={`text-gray-700} block text-sm font-bold`}>
                                                <p>Location</p>
                                            </label>
                                            <Field
                                                type="text"
                                                id="location"
                                                name="location"
                                                autoComplete="off"
                                                onChange={handleChange}
                                                value={values.location}
                                                className={`focus:ring-none mt-2 block w-full rounded-md border border-gray-200 bg-white px-4 py-2 text-sm text-gray-700 shadow-sm focus:border-gray-200 focus:outline-none`}
                                            />
                                        </div>
                                        <div className="col-span-2 mb-4">
                                            <label
                                                htmlFor="description"
                                                className={`text-gray-700} block text-sm font-bold`}>
                                                <p>Description</p>
                                            </label>
                                            <Field
                                                type="textarea"
                                                as="textarea"
                                                id="description"
                                                name="description"
                                                autoComplete="off"
                                                onChange={handleChange}
                                                value={values.description}
                                                className={`focus:ring-none mt-2 block h-80 w-full rounded-md border border-gray-200 bg-white px-4 py-2 text-sm text-gray-700 shadow-sm focus:border-gray-200 focus:outline-none`}
                                            />
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
                <div
                    className={`mt-2 flex items-center justify-end border-t px-10 pt-5`}>
                    {job && (
                        <button
                            onClick={() => handleDelete()}
                            className={`mx-2 inline-flex w-auto cursor-pointer select-none appearance-none items-center justify-center space-x-1 rounded-md border border-red-200 bg-red-200 px-3 py-1 text-sm font-medium text-red-800`}>
                            Delete job
                        </button>
                    )}
                    <Link to={`/boards/${boardId}/board`}>
                        <button
                            className={`mx-2 inline-flex w-auto cursor-pointer select-none appearance-none items-center justify-center space-x-1 rounded-md border border-gray-200 bg-gray-200 px-3 py-1 text-sm font-medium text-gray-800`}>
                            Exit
                        </button>
                    </Link>
                    {!job && <BucketSelect />}
                    <button
                        disabled={savingJob}
                        onClick={() => formRef.current?.submitForm()}
                        className={`mx-2 inline-flex w-auto cursor-pointer select-none appearance-none items-center justify-center space-x-1 rounded-md border border-primary bg-primary px-3 py-1 text-sm font-medium text-white`}>
                        {savingJob ? (
                            <>
                                <svg
                                    className="h-4 w-4 animate-spin"
                                    viewBox="3 3 18 18">
                                    <path
                                        className="fill-blue-800"
                                        d="M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"></path>
                                    <path
                                        className="fill-blue-100"
                                        d="M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z"></path>
                                </svg>
                            </>
                        ) : (
                            <>Save & Close</>
                        )}
                    </button>
                </div>
            </div>
        </>
    );
};

export default JobForm;
