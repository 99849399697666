import axiosInstance from './AxiosInstance';

export async function setTourToViewed(): Promise<any> {
    try {
        const response = await axiosInstance().put('user/viewed-tutorial', {
            viewed_tutorial: true,
        });
        return response.data;
    } catch (error) {
        console.error('Error updating guided tour:', error);
        return null;
    }
}

// export async function userLogin(email:string,password:string): Promise<any> {
//     try {

//         return true;
//         // const response = await axiosInstance().put('user/viewed-tutorial', {
//         //     viewed_tutorial: true,
//         // });
//         // return response.data;
//     } catch (error) {
//         // console.error('Error updating guided tour:', error);
//         // return null;
//     }
// }

// export async function userRegister(firstName:string,email:string,password:string): Promise<any> {
//     try {
//         return true;
//         // const response = await axiosInstance().put('user/viewed-tutorial', {
//         //     viewed_tutorial: true,
//         // });
//         // return response.data;
//     } catch (error) {
//         console.error('Error updating guided tour:', error);
//         return null;
//     }
// }

// export async function userForgotPassword(emailId:string): Promise<any> {
//     try {
//         return true;
//         // const response = await axiosInstance().put('user/viewed-tutorial', {
//         //     viewed_tutorial: true,
//         // });
//         // return response.data;
//     } catch (error) {
//         // console.error('Error updating guided tour:', error);
//         // return null;
//     }
// }

// export async function userResetPassword(password:string,confirmPassword:string): Promise<any> {
//     // try {
//     //     const response = await axiosInstance().put('user/viewed-tutorial', {
//     //         viewed_tutorial: true,
//     //     });
//     //     return response.data;
//     // } catch (error) {
//     //     console.error('Error updating guided tour:', error);
//     //     return null;
//     // }
// }
