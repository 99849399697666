import React from 'react';
import { Link } from 'react-router-dom';
import HeaderComponent from '../../pages/onboarding/components/HeaderComponent';
import FooterComponent from '../../pages/onboarding/components/FooterComponent';

const Error500 = () => {
    return (
        <div>
            <HeaderComponent />
            <section className="bg-white">
                <div className="container mx-auto flex min-h-screen items-center px-6 py-12">
                    <div>
                        <p className="text-sm font-medium text-blue-500">
                            500 error
                        </p>
                        <h1 className="mt-3 text-2xl font-semibold text-gray-800 md:text-3xl">
                            We ran into some trouble
                        </h1>
                        <p className="mt-4 text-gray-500">
                            Sorry, something went wrong.
                        </p>

                        <div className="mt-6 flex items-center gap-x-3">
                            <Link to="/">
                                <button
                                    className="w-1/2 shrink-0 rounded-lg bg-primary px-5 py-2 text-sm
                                    tracking-wide text-white transition-colors duration-200 sm:w-auto">
                                    Take me home
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <FooterComponent />
        </div>
    );
};

export default Error500;
