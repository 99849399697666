import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';

function ForgotPasswordForm() {
    const initialValues = {
        email: '',
    };

    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid email address').required('Required'),
    });

    const onSubmit = (values: any) => {
        console.log(values);
        // You can make a request to your backend API here to send a reset password link to the user's email
    };

    return (
        <div className="h-screen w-1/2 border border-red-500 bg-gray-200">
            <div className="mx-auto my-10 max-w-md">
                <h2 className="mb-4 text-xl font-bold">Forgot Password</h2>
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}>
                    {({ isSubmitting }) => (
                        <Form>
                            <div className="mb-4">
                                <label
                                    htmlFor="email"
                                    className="mb-1 block font-bold">
                                    Email Address
                                </label>
                                <Field
                                    type="email"
                                    id="email"
                                    name="email"
                                    className="w-full border border-gray-300 p-2"
                                />
                                <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="mt-1 text-sm text-red-500"
                                />
                            </div>
                            <button
                                type="submit"
                                className="rounded bg-blue-500 px-4 py-2 text-white disabled:cursor-not-allowed disabled:bg-gray-400"
                                disabled={isSubmitting}>
                                {isSubmitting
                                    ? 'Sending...'
                                    : 'Send Password Reset Link'}
                            </button>
                            <p>Reset link will be send to your email</p>
                            <Link to="/login">
                                <div>Sign in</div>
                            </Link>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default ForgotPasswordForm;
