import { Link } from 'react-router-dom';
import React from 'react';
import { Heart } from 'phosphor-react';

import logo from '../../../assets/images/logo/eztrackr-logo.svg';

const FooterComponent = () => {
    return (
        <footer className="body-font border-t text-gray-600">
            <div className="container mx-auto flex flex-col flex-wrap justify-between px-5 py-24 md:flex-row md:flex-nowrap md:items-center lg:items-start">
                <div className="mx-auto w-64 flex-shrink-0 text-center md:mx-0 md:text-left">
                    <Link
                        to="/"
                        className="title-font flex items-center justify-center font-medium text-gray-900 md:justify-start">
                        <img src={logo} width={130} alt="Eztrackr logo" />
                    </Link>
                </div>
                <div className="-mb-10 mt-10 flex flex-grow flex-wrap justify-end text-center md:mt-0 md:pl-20 md:text-left">
                    <div className="w-full px-4 md:w-1/2 lg:w-1/4">
                        <p className="mb-3 text-lg font-semibold tracking-wider text-gray-400">
                            Links
                        </p>
                        <nav className="mb-10 list-none">
                            <li>
                                <Link
                                    to="/onboarding"
                                    className="text-base text-gray-600 hover:text-gray-800">
                                    Sign up for free
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="https://chrome.google.com/webstore/detail/eztrackr/kdpbamlhffmfbgglmaedhopenkpgkfdg"
                                    className="text-base text-gray-600 hover:text-gray-800">
                                    Chrome extension
                                </Link>
                            </li>
                        </nav>
                    </div>
                    <div className="w-full px-4 md:w-1/2 lg:w-1/4">
                        <p className="mb-3 text-lg font-semibold tracking-wider text-gray-400">
                            Eztrackr
                        </p>
                        <nav className="mb-10 list-none">
                            <li>
                                <Link
                                    target={`_blank`}
                                    to="https://docs.google.com/forms/d/e/1FAIpQLSfWy2YIBPeTe7phoNWhWtw7e2VADZ1FxM_JcYKlpZET6bATkw/viewform?usp=sf_link"
                                    className="text-base text-gray-600 hover:text-gray-800">
                                    Contact us
                                </Link>
                            </li>
                            <li>
                                <Link
                                    target={`_blank`}
                                    to="https://docs.google.com/forms/d/e/1FAIpQLSfWy2YIBPeTe7phoNWhWtw7e2VADZ1FxM_JcYKlpZET6bATkw/viewform?usp=sf_link"
                                    className="text-base text-gray-600 hover:text-gray-800">
                                    Share feedback
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/terms-and-conditions"
                                    className="text-base text-gray-600 hover:text-gray-800">
                                    Terms of service
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/privacy-policy"
                                    className="text-base text-gray-600 hover:text-gray-800">
                                    Privacy policy
                                </Link>
                            </li>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="bg-gray-100">
                <div className="container mx-auto flex flex-col flex-wrap px-5 py-4 sm:flex-row">
                    <p className="flex items-center justify-center text-center text-base text-gray-500 sm:text-left">
                        © 2023 Eztrackr — Made with{' '}
                        <span className="px-1">
                            <Heart weight="fill" color="#f14668" />
                        </span>{' '}
                        in Canada and India
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default FooterComponent;
