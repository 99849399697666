import React from 'react';

interface BoardContextInterface {
    updateBoard: () => Promise<void>;
    boardId: string;
}

const BoardContext = React.createContext<BoardContextInterface | null>(null);

export default BoardContext;
