import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import JobForm from '../forms/JobForm';
import { getJob, getBucketsByBoard } from '../../../../../shared/services';
import { JobModel, SelectModel } from '../../../../../shared/models';
import Dashboard from '../../../Dashboard';

type BoardParams = {
    boardId: string;
    jobId?: string;
    bucketId?: string;
};

const JobModal = () => {
    const navigate = useNavigate();
    const { boardId, jobId, bucketId } = useParams<
        keyof BoardParams
    >() as BoardParams;
    const [loading, setLoading] = React.useState<boolean>(true);

    const [job, setJob] = React.useState<JobModel>();
    const [buckets, setBuckets] = React.useState<SelectModel[]>([]);

    const fetchData = async () => {
        if (jobId) {
            const jobResponse = await getJob(jobId);
            await setJob(jobResponse);
            document.title = jobResponse.name + ' Details';
        }
        const bucketsResponse = await getBucketsByBoard(boardId);
        await setBuckets(bucketsResponse);
        setLoading(false);
    };

    useEffect(() => {
        // FIXME: Not sure why this is not working?
        if (!jobId) {
            document.title = 'Add Job';
        }
        fetchData();
    }, []);

    return (
        <div>
            <Dashboard />

            <div
                className={`fixed left-0 top-0 z-10 h-screen w-screen bg-black bg-opacity-50`}
                onClick={() => navigate(`/boards/${boardId}/board`)}
            />

            <div
                className={`absolute left-1/2 top-1/2 z-20 h-[90%] w-full
            max-w-screen-lg -translate-x-1/2 -translate-y-1/2 transform px-4 md:px-20 lg:px-0`}>
                <div
                    className={`h-full transform overflow-auto rounded-lg bg-white py-5 text-left
            align-bottom shadow-xl transition-all md:py-10`}>
                    {loading && <div>Loading...</div>}
                    {!loading && (
                        <>
                            {job && (
                                <JobForm
                                    boardId={boardId}
                                    job={job}
                                    buckets={buckets}
                                />
                            )}
                            {!job && (
                                <JobForm
                                    boardId={boardId}
                                    buckets={buckets}
                                    bucketId={bucketId}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default JobModal;
