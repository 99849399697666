import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

import PrivateRoute from './PrivateRoute';
import LandingPage from './pages/onboarding/LandingPage';
import PrivacyPolicy from './pages/onboarding/privacy-policy/PrivacyPolicy';
import TermsAndCondition from './pages/onboarding/terms-and-condition/TermsAndCondition';
import Error404 from './shared/messages/Error404';
import Dashboard from './pages/board/Dashboard';
import { UserData } from './contexts/UserDataContext';
import UserProviders from './providers/UserProvider';
import axiosInstance from './shared/services/AxiosInstance';
import Loading from './shared/messages/Loading';
import ImportFromTrello from './pages/onboarding/ImportFromTrello';
import LoadBoard from './pages/board/LoadBoard';
import JobModal from './pages/board/kanban/components/modals/JobModal';
import ForgotPassword from './pages/onboarding/ForgotPassword';
import ResetPassword from './pages/onboarding/ResetPassword';
import ExtensionUninstalled from './pages/onboarding/ExtensionUninstalled';
import ExtensionGettingStarted from './pages/onboarding/ExtensionGettingStarted';
import { saveAccessTokenInExtensionStorage } from './shared/util';
import Register1 from './pages/onboarding/Register1';
import ChromeExtensionContext from './contexts/ChromeExtensionContext';

const App = () => {
    const [userData, setUserData] = useState<UserData>({
        id: null,
        name: null,
        email: null,
        viewed_tutorial: null,
    });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const location = useLocation();
    const [isDirectedFromChromeExtension] = useState<boolean>(
        location?.pathname === '/chrome-extension'
    );

    useEffect(() => {
        axiosInstance()
            .get<UserData>('/currentUser')
            .then((response) => {
                setIsLoading(false);
                setUserData({
                    id: response.data.id,
                    name: response.data.name,
                    email: response.data.email,
                    viewed_tutorial: response.data.viewed_tutorial,
                });

                saveAccessTokenInExtensionStorage().then(() => {
                    console.log('Saved token in extension storage');
                });
            })
            .catch((error) => {
                setIsLoading(false);
                console.error(error);
                throw error;
            });
    }, []);

    ReactGA.initialize(process.env.REACT_APP_G_ANALYTICS || 'G-EX9EQR1PG3');

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <UserProviders user={userData}>
                    <ChromeExtensionContext.Provider
                        value={{ isDirectedFromChromeExtension }}>
                        <Routes>
                            <Route path="/" element={<LandingPage />} />
                            <Route path="/register" element={<Register1 />} />
                            <Route
                                path="/chrome-extension"
                                element={<Register1 />}
                            />
                            {/* <Route path="/login" element={<Login />} /> */}
                            <Route
                                path="/forgot-password"
                                element={<ForgotPassword />}
                            />
                            <Route
                                path="/reset-password/:magicLink"
                                element={<ResetPassword />}
                            />

                            <Route
                                path="/privacy-policy"
                                element={<PrivacyPolicy />}
                            />
                            <Route
                                path="/terms-and-conditions"
                                element={<TermsAndCondition />}
                            />

                            <Route path="*" element={<Error404 />} />

                            <Route
                                path={'/import-from-trello'}
                                element={
                                    <PrivateRoute>
                                        <ImportFromTrello />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="/dashboard"
                                element={
                                    <PrivateRoute>
                                        <LoadBoard />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="/boards/:boardId/:subpage"
                                element={
                                    <PrivateRoute>
                                        <Dashboard />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="/boards/:boardId/jobs/:jobId"
                                element={
                                    <PrivateRoute>
                                        <JobModal />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="/boards/:boardId/add-job"
                                element={
                                    <PrivateRoute>
                                        <JobModal />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="/extension-getting-started"
                                element={<ExtensionGettingStarted />}
                            />

                            <Route
                                path="/extension-uninstalled"
                                element={<ExtensionUninstalled />}
                            />

                            <Route
                                path="/boards/:boardId/bucket/:bucketId/add-job"
                                element={
                                    <PrivateRoute>
                                        <JobModal />
                                    </PrivateRoute>
                                }
                            />
                        </Routes>
                    </ChromeExtensionContext.Provider>
                </UserProviders>
            )}
        </>
    );
};

export default App;
