import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Plus, WarningCircle } from 'phosphor-react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

import { BoardModel, JobModel, BucketModel } from '../../../shared/models';
import Bucket from './components/Bucket';
import { getFirstBoard, saveBoard } from '../../../shared/services';
import SearchBox from './components/shared/SearchBox';

type board = {
    board: BoardModel;
};
const colors = ['#3e0087'];

const Kanban: React.FC<board> = ({ board }) => {
    const [data, setData] = useState<BoardModel>();
    const [filterData, setFilterData] = useState<BoardModel>();
    const [showNotification, setShowNotification] = useState(false);

    useEffect(() => {
        setData(board);
        setFilterData(board);
    }, [board]);

    const handleBoardChange = async (newBoard: BoardModel) => {
        setFilterData(newBoard);

        try {
            await saveBoard(newBoard);
            setData(await getFirstBoard());
        } catch (error) {
            setData(filterData); // Revert to old state
            setShowNotification(true);
            setTimeout(() => {
                setShowNotification(false);
            }, 3000);
        }
    };

    const handleFilterChange = (query: any) => {
        const filteredJobsIds = Object.values(
            (data as { jobs?: Record<string, JobModel> })?.jobs || {}
        )
            .filter((job) =>
                job.name.toLowerCase().includes(query.toLowerCase())
            )
            .map((job) => job.id);

        const filteredBuckets = Object.values(data?.buckets ?? {}).map(
            (bucket) => {
                const updatedJobIds = bucket.jobIds.filter((jobId) =>
                    filteredJobsIds.includes(jobId)
                );
                return { ...bucket, jobIds: updatedJobIds };
            }
        );

        const newData = { ...filterData };

        if (!filterData) {
            return;
        }

        newData.buckets = filteredBuckets.reduce(
            (acc: Record<string, BucketModel>, bucket) => {
                acc[bucket.id] = bucket;
                return acc;
            },
            {}
        );

        setFilterData(newData as BoardModel);
    };

    const onDragEnd = async (result: any) => {
        const { destination, source, draggableId, type } = result;

        if (!destination) {
            return;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        // Handle bucket reordering
        if (type === 'bucket') {
            const newBucketOrder = Array.from(filterData?.bucketOrder ?? []);
            newBucketOrder.splice(source.index, 1);
            newBucketOrder.splice(destination.index, 0, draggableId);

            const newState = {
                ...filterData,
                bucketOrder: newBucketOrder,
            };

            await handleBoardChange(newState as BoardModel);

            return;
        }

        const start = filterData?.buckets[source.droppableId];
        const finish = filterData?.buckets[destination.droppableId];
        if (!start || !finish) {
            return;
        }
        if (start === finish) {
            const newJobIds = Array.from(start.jobIds);
            newJobIds.splice(source.index, 1);
            newJobIds.splice(destination.index, 0, draggableId);

            const newBucket = {
                ...start,
                jobIds: newJobIds,
            };

            const newState = {
                ...filterData,
                buckets: {
                    ...filterData?.buckets,
                    [newBucket.id]: newBucket,
                },
            };

            await handleBoardChange(newState);
            return;
        }

        // Moving from one list to another
        const startJobIds = Array.from(start.jobIds);
        startJobIds.splice(source.index, 1);
        const newStart = {
            ...start,
            jobIds: startJobIds,
        };

        const finishJobIds = Array.from(finish.jobIds);
        finishJobIds.splice(destination.index, 0, draggableId);
        const newFinish = {
            ...finish,
            jobIds: finishJobIds,
        };

        const newState = {
            ...filterData,
            buckets: {
                ...filterData?.buckets,
                [newStart.id]: newStart,
                [newFinish.id]: newFinish,
            },
        };

        await handleBoardChange(newState);
    };

    return (
        <>
            {showNotification && (
                <div className={`absolute right-2 top-2`}>
                    <div className="flex overflow-hidden rounded-lg bg-white shadow-lg">
                        <div className="flex items-center justify-center bg-red-500 px-3">
                            <WarningCircle
                                size={32}
                                weight="fill"
                                color="#FFF"
                            />
                        </div>

                        <div className="-mx-3 px-4 py-2">
                            <div className="mx-3">
                                <span className="font-semibold text-red-500">
                                    Something went wrong
                                </span>
                                <p className="text-sm text-gray-600">
                                    We weren't able to save the changes.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className={`flex h-full flex-col`}>
                <div
                    className={`flex h-14 w-full items-center justify-between border-b px-4`}>
                    <div>
                        <h2 className={`text-md font-semibold md:text-xl`}>
                            {board.name}
                        </h2>
                    </div>
                    <div className={`flex items-center gap-2`}>
                        <div className={`flex items-center`}>
                            {/*<Link to="">
                                <button
                                    className="flex items-center rounded border px-4 py-1 text-sm
                                focus:outline-none">
                                    <Funnel size={16} />
                                    <p className={`ml-1`}>Filter</p>
                                </button>
                            </Link>
                            */}
                        </div>
                        <div className={`flex items-center`}>
                            <SearchBox
                                handleFilterChange={
                                    handleFilterChange
                                }></SearchBox>
                        </div>
                        <div className={`flex cursor-pointer items-center`}>
                            <Link
                                to={`/boards/${board.id}/add-job`}
                                onClick={() => {
                                    ReactGA.event({
                                        category: 'Add Job',
                                        action: 'User clicked on add job button from kanban board',
                                    });
                                }}>
                                <button
                                    className="flex items-center rounded border-0 bg-primary
                                px-4 py-1 text-sm text-white focus:outline-none">
                                    <Plus
                                        className={`hidden sm:block`}
                                        size={16}
                                        weight="fill"
                                        fill="#FFF"
                                    />
                                    <p className={`ml-1 text-white`}>Add job</p>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>

                <div
                    className={`flex flex-grow overflow-x-auto bg-gray-board`}
                    style={{ height: 'calc(100vh - 56px - 56px)' }}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable
                            droppableId="all-buckets"
                            direction="horizontal"
                            type="bucket">
                            {(provided) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className={`flex h-full`}>
                                    {filterData &&
                                        filterData.bucketOrder.map(
                                            (bucketId: string, index) => {
                                                const bucket =
                                                    filterData.buckets[
                                                        bucketId
                                                    ];
                                                const jobs = bucket.jobIds.map(
                                                    (jobId: string) =>
                                                        filterData.jobs[jobId]
                                                );
                                                return (
                                                    <Bucket
                                                        key={bucket.id}
                                                        bucket={bucket}
                                                        jobs={jobs}
                                                        index={index}
                                                        buckets={
                                                            filterData.buckets
                                                        }
                                                        color={
                                                            colors[
                                                                index %
                                                                    colors.length
                                                            ]
                                                        }
                                                    />
                                                );
                                            }
                                        )}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </div>
        </>
    );
};

export default Kanban;
