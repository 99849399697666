import React, { useEffect, useContext } from 'react';
import HeaderComponent from './components/HeaderComponent';
import FooterComponent from './components/FooterComponent';
import SignInWithGoogle from './SignInWithGoogle';
import { useNavigate } from 'react-router-dom';

import { UserDataContext } from '../../contexts/UserDataContext';
import ChromeExtensionContext from '../../contexts/ChromeExtensionContext';

const Register1 = () => {
    const navigate = useNavigate();
    const { currentUser } = useContext(UserDataContext);

    const isDirectedFromChromeExtension = useContext(
        ChromeExtensionContext
    )?.isDirectedFromChromeExtension;

    useEffect(() => {
        document.title = isDirectedFromChromeExtension
            ? 'Sign in for Chrome extension | Eztrackr'
            : 'Sign in | Eztrackr';
        if (currentUser?.id) {
            navigate('/dashboard');
        }
    }, []);

    return (
        <>
            <div className="flex flex-col">
                <HeaderComponent />

                <div className="flex h-screen flex-col items-center justify-center">
                    <p className="text-2xl leading-relaxed">
                        {isDirectedFromChromeExtension
                            ? "Sign in use Eztrackr's Chrome extension"
                            : "Let's get you started with Eztrackr"}
                    </p>
                    <p className={`mb-5 text-sm`}>
                        Sign up with email and password coming soon
                    </p>
                    <SignInWithGoogle />
                </div>
                <FooterComponent />
            </div>
        </>
    );
};

export default Register1;
