import React, { useEffect, useState } from 'react';
import {
    Bar,
    BarChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Cell,
    ResponsiveContainer,
    LabelList,
    PieChart,
    Pie,
    PieLabelRenderProps,
    Tooltip,
    Legend,
} from 'recharts';
import { RocketLaunch } from 'phosphor-react';
import ReactGA from 'react-ga4';

import './statistics.css';
import { BoardModel, BucketModel } from '../../../shared/models';

type board = {
    board: BoardModel;
};

type BarGraphData = {
    [key: string]: number | string;
};

const generateColors = (count: number) => {
    const colors = [
        '#3ec0dd',
        '#38b2cf',
        '#32a5c1',
        '#2c97b3',
        '#268aa5',
        '#207c97',
        '#1a6f89',
        '#14627b',
        '#0e546d',
        '#08475f',
    ];
    return colors.slice(0, count);
};

const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
}: PieLabelRenderProps) => {
    if (
        cx === undefined ||
        cy === undefined ||
        innerRadius === undefined ||
        percent === undefined
    ) {
        return null;
    }

    const RADIAN = Math.PI / 180;
    const radius =
        (innerRadius as number) +
        ((outerRadius as number) - (innerRadius as number)) * 0.5;
    const x = (cx as number) + radius * Math.cos(-midAngle * RADIAN);
    const y = (cy as number) + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text
            x={x}
            y={y}
            fill="white"
            textAnchor={x > (cx as number) ? 'start' : 'end'}
            dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

const BoardStatistics: React.FC<board> = ({ board }) => {
    const [jobCount, setJobCount] = useState(0);
    const [graphData, setGraphData] = useState<BarGraphData[]>([]);

    useEffect(() => {
        const calculate = () => {
            let count = 0;
            const data: BarGraphData[] = [];

            Object.keys(board.buckets).forEach((bucketId) => {
                const bucket = board.buckets[bucketId] as BucketModel;

                count += bucket.jobIds.length;
                data.push({ name: bucket.name, jobs: bucket.jobIds.length });
            });

            setJobCount(count);
            setGraphData(data);
        };

        ReactGA.event({
            category: 'Statistics',
            action: 'Viewed Statistics',
        });

        document.title = 'Board Statistics';

        calculate();
    }, [board]);

    const colors = generateColors(board.bucketOrder.length);

    return (
        <>
            <div className={`px-5 py-12`}>
                <div className={`mb-5 px-5`}>
                    <h1 className={`mb-5 text-2xl font-bold`}>Statistics</h1>
                    <p>
                        {' '}
                        Welcome to your personal statistics dashboard! We've
                        gathered some insightful data to help you stay on top of
                        your job search journey. Get a clear understanding of
                        your progress, identify trends, and make data-driven
                        decisions to land your dream job. So, buckle up, and
                        let's dive into the numbers that matter!
                    </p>
                </div>

                <div
                    className={`flex flex-col flex-wrap items-center lg:flex-row xl:items-start`}>
                    <div className={`data-container`}>
                        <div className={`description`}>
                            <h1>Jobs per list</h1>
                            <p>
                                Get a quick overview of the number of jobs in
                                each stage of your search. Keep an eye on your
                                pipeline to ensure you're always making
                                progress.
                            </p>
                        </div>
                        <div className={`data`}>
                            <ResponsiveContainer>
                                <BarChart
                                    data={graphData}
                                    margin={{ top: 30, bottom: 60, left: 30 }}>
                                    <CartesianGrid stroke="none" />
                                    <XAxis
                                        dataKey="name"
                                        angle={-45}
                                        textAnchor="end"
                                        interval={0}
                                    />
                                    <YAxis hide />
                                    <Bar dataKey="jobs">
                                        {graphData.map((entry, index) => (
                                            <Cell
                                                key={`cell-${index}`}
                                                fill={
                                                    colors[
                                                        index % colors.length
                                                    ]
                                                }
                                            />
                                        ))}
                                        <LabelList
                                            dataKey="jobs"
                                            position="top"
                                            fill="#000000"
                                            style={{ fontWeight: 'bold' }}
                                        />
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                    <div className={`data-container`}>
                        <div className={`description`}>
                            <h1>Application Status Breakdown</h1>
                            <p>
                                Stay informed about the distribution of your job
                                applications across different stages.
                            </p>
                        </div>
                        <div className={`data`}>
                            <ResponsiveContainer>
                                <PieChart>
                                    <Pie
                                        data={graphData}
                                        dataKey="jobs"
                                        nameKey="name"
                                        cx="50%"
                                        cy="50%"
                                        outerRadius="80%"
                                        label={renderCustomizedLabel}
                                        labelLine={false}
                                        animationDuration={500}>
                                        {graphData.map((entry, index) => (
                                            <Cell
                                                key={`cell-${index}`}
                                                fill={
                                                    colors[
                                                        index % colors.length
                                                    ]
                                                }
                                            />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                    <Legend />
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                    <div className={`data-container`}>
                        <div className={`description`}>
                            <h1>Jobs saved</h1>
                            <p>
                                Total number of jobs you have saved in this
                                board.
                            </p>
                        </div>
                        <div className={`data`}>
                            <div
                                className={`flex h-full w-full flex-col items-center justify-center`}>
                                <h1 className={`text-5xl`}>{jobCount}</h1>
                            </div>
                        </div>
                    </div>
                    <div className={`data-container`}>
                        <div className={`description`}>
                            <h1>More stats coming soon</h1>
                            <p>
                                Stay tuned while we add more powerful
                                statistics!
                            </p>
                        </div>
                        <div className={`data`}>
                            <div
                                className={`flex h-full w-full flex-col items-center justify-center`}>
                                <RocketLaunch
                                    size={45}
                                    fill="#3ec0dd"
                                    weight="fill"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BoardStatistics;
