import axiosInstance from './AxiosInstance';
import { AxiosResponse } from 'axios';

import { BoardModel } from '../models';

export async function getFirstBoard(): Promise<any> {
    return await axiosInstance()
        .get<{ data: BoardModel }>('boards/')
        .then((response) => {
            return response.data.data;
        })
        .catch((error) => {
            console.error('Error fetching boards:', error);
            return [];
        });
}

export async function saveBoard(
    board: BoardModel
): Promise<AxiosResponse<BoardModel>> {
    return axiosInstance().put<BoardModel>(`boards/${board.id}`, board);
}

export async function changeBoardName(
    id: string,
    name: string
): Promise<AxiosResponse<BoardModel>> {
    return axiosInstance().put<BoardModel>(`boards/${id}/update-name`, {
        name: name,
    });
}

export async function importBoardData(
    importBoardData: any
): Promise<AxiosResponse<BoardModel>> {
    return axiosInstance().post(`boards/import-board-data`, importBoardData);
}
