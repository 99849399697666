import React from 'react';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

function ResetPasswordForm() {
    const initialValues = {
        password: '',
        confirmPassword: '',
    };

    const validationSchema = Yup.object({
        password: Yup.string()
            .min(6, 'Password must be at least 6 characters')
            .required('Required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password')], 'Passwords must match')
            .required('Required'),
    });

    const onSubmit = (values: any) => {
        console.log(values);
        // You can make a request to your backend API here to reset the user's password
    };

    return (
        <div className="h-screen w-1/2 border border-red-500 bg-gray-200">
            <div className="mx-auto max-w-md">
                <h2 className="mb-4 text-xl font-bold">Reset Password</h2>
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}>
                    {({ isSubmitting }) => (
                        <Form>
                            <div className="mb-4">
                                <label
                                    htmlFor="password"
                                    className="mb-1 block font-bold">
                                    New Password
                                </label>
                                <Field
                                    type="password"
                                    id="password"
                                    name="password"
                                    className="w-full border border-gray-300 p-2"
                                />
                                <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="mt-1 text-sm text-red-500"
                                />
                            </div>
                            <div className="mb-4">
                                <label
                                    htmlFor="confirmPassword"
                                    className="mb-1 block font-bold">
                                    Confirm New Password
                                </label>
                                <Field
                                    type="password"
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    className="w-full border border-gray-300 p-2"
                                />
                                <ErrorMessage
                                    name="confirmPassword"
                                    component="div"
                                    className="mt-1 text-sm text-red-500"
                                />
                            </div>
                            <button
                                type="submit"
                                className="rounded bg-blue-500 px-4 py-2 text-white disabled:cursor-not-allowed disabled:bg-gray-400"
                                disabled={isSubmitting}>
                                {'Reset Password'}
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default ResetPasswordForm;
